import { IonRow, IonCol } from '@ionic/vue';
import CalendarItem from '../calendar-item';
import {IntervalEnum} from '@/constants/enums';
//import utilService from '@/services/utilService';

export default {
    components: {
        IonRow,
        IonCol,
        CalendarItem
    },

    props: {
        parent: null,
        startTimeUnit: null,
        timeUnits: null,
        rootDate: Date,
        intervalEnum: IntervalEnum,
        calendarEvents: null,
        weekDays: null,
        months: null,
        aspectTemplate: null,
        outerLimit: null,
    },
    data() {
        return {
            now: new Date()            
        }
    },
//    watch: {
//        rootDate: function (/*val, oldVal*/) {            
//            this.init();
//        }
//    },
    inject: ["languageService"],
    emits: ["onSelect"],
//    mounted() {
////        this.languageService.getText(EnumResourceStrings.Days).then(x => this.labels.strDays = x);
////        this.languageService.getText(EnumResourceStrings.Hours).then(x => this.labels.strHours = x);
////        this.languageService.getText(EnumResourceStrings.Minutes).then(x => this.labels.strMinutes = x);
////        this.languageService.getText(EnumResourceStrings.Weeks).then(x => this.labels.strWeeks = x);
////        this.languageService.getText(EnumResourceStrings.Month).then(x => this.labels.strMonth = x);
////        this.onInit();
////        this.init();
//    },
    computed: {
        calendarHeader: function () {
 
            var result = '';
            var weekDay = '';
            var month = '';
            if (this.weekDays) {                                            
                weekDay = this.weekDays[this.rootDate.getDay()];
            }
            if (this.months) {                
                month = this.months[this.rootDate.getMonth()];
            }
            if(weekDay && month){
                result = this.languageService.formatFullDate(this.rootDate, weekDay, month);
            }            
            return result;
        }
    },
    methods: {
        select: function (locationCombination, profileEvent) {
            this.$emit('onSelect', locationCombination, profileEvent);
        },
        
    },
}