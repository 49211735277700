<ion-row>
    <ion-col>
        <div v-if="calendarHeader" v-html="calendarHeader" class="calendarHeader"></div>
    </ion-col>
</ion-row>    
<ion-row>
    <ion-col size-md="3" size-xs="12">        
        <div class="tag first">{{tag(0)}} {{tag2(0)}}</div>
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="null" 
                       :now="now"
                       :showDetails="true"                       
                       :calendarEvents="calendarEvents"
                       :aspectTemplate="aspectTemplate"                       
                       :outerLimit="outerLimit"
                       :intervalEnum="intervalEnum"                       
                       @onSelect="select"
                       ></calendar-item>                
    </ion-col>
    <ion-col size-md="3"  size-xs="12" v-for="(timeUnit,index) in timeUnits" :key="index">            
        <div v-if="index % 4 == 3" class="tag first">{{tag((index + 1) / 4)}} {{tag2((index + 1) / 4)}}</div>
        <div v-if="index % 4 == 0" class="tag hideiftoosmall">&nbsp;</div>
        <div v-if="index % 4 == 1" class="tag hideiftoosmall">&nbsp;</div>
        <div v-if="index % 4 == 2" class="tag hideiftoosmall last">&nbsp;</div>
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="timeUnit" 
                       :now="now"
                       :showDetails="true"                       
                       :calendarEvents="calendarEvents"
                       :aspectTemplate="aspectTemplate"                       
                       :outerLimit="outerLimit"
                       :intervalEnum="intervalEnum"                                                  
                       @onSelect="select"
                       ></calendar-item>

    </ion-col>    
</ion-row>