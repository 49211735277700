import { IonGrid, IonRow, IonCol, IonItem, IonIcon } from '@ionic/vue';

export default {
    components: {IonGrid, IonRow, IonCol, IonItem, IonIcon},
    props: {
        event: null,
        aspectTemplate: null,
        locationCombination: null,
        outerLimit: null
    },
    data() {
        return {
            luckFigure: null
        }
    },
    watch: {
        event: function (/*val, oldVal*/) {
            this.init();
        },
        locationCombination: function (/*val, oldVal*/) {
            this.init();
        }
    },
    inject: ["luckService"],
    mounted() {
        this.init();
    },

//    updated: function () {
//
//    },

    computed: {
        surfaceDeepMode: function () {
            return this.aspectTemplate && this.aspectTemplate.propertiesVm && this.aspectTemplate.propertiesVm.surfaceDeepMode;
        },
    },
//    emits: ["onChange", "onFoldersChanged"],
    methods: {
        init: function () {
            var eventDate = this.event.profileEvent.date;
            var locationCombination = this.event.locationCombination;
            this.luckService.getLuckFigures(this.aspectTemplate, this.outerLimit, [], eventDate, false, false, locationCombination.birthLocation,
                    locationCombination.eventLocation, locationCombination.birthDate).then(x => {
                this.luckFigure = x.luckFigures[0];
            });
        },
    }

}