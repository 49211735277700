import { IonRow, IonCol } from '@ionic/vue';
import CalendarItem from '../calendar-item';
import {IntervalEnum} from '@/constants/enums';

export default {
    components: {
        IonRow,
        IonCol,
        CalendarItem
    },

    props: {
        parent: null,
        startTimeUnit: null,
        timeUnits: null,
        rootDate: Date,    
        intervalEnum: IntervalEnum,
        calendarEvents: null
    },
    data() {
        return {
            now: new Date()
        }
    },
//    watch: {
//        rootDate: function (/*val, oldVal*/) {            
//            this.init();
//        }
//    },
    inject: ["languageService"],
    emits: ["onSelect"],
    mounted() {
//        this.languageService.getText(EnumResourceStrings.Days).then(x => this.labels.strDays = x);
//        this.languageService.getText(EnumResourceStrings.Hours).then(x => this.labels.strHours = x);
//        this.languageService.getText(EnumResourceStrings.Minutes).then(x => this.labels.strMinutes = x);
//        this.languageService.getText(EnumResourceStrings.Weeks).then(x => this.labels.strWeeks = x);
//        this.languageService.getText(EnumResourceStrings.Month).then(x => this.labels.strMonth = x);
//        this.onInit();
    },
    computed: {
//        years:function(){
//            var currentYear = this.rootDate.getYear();            
//            var startYear = currentYear - (currentYear % 12);
//            var result = [];
//            for(var i = 0; i < 12;i++){
//                result.push(startYear + i);
//            }
//            return result;
//        },        
    },
    methods: {
        select: function (locationCombination, profileEvent) {
            this.$emit('onSelect', locationCombination, profileEvent);
        }
    },
}