import { IonRow, IonCol } from '@ionic/vue';
import CalendarItem from '../calendar-item';
import {IntervalEnum} from '@/constants/enums';
import utilService from '@/services/utilService';

export default {
    components: {
        IonRow,
        IonCol,
        CalendarItem
    },

    props: {
        parent: null,
        startTimeUnit: null,
        timeUnits: null,
        rootDate: Date,
        intervalEnum: IntervalEnum,
        calendarEvents: null,
        weekDays: null,
        shortWeekDays: null,
        months: null,
        aspectTemplate: null,
        outerLimit: null,
    },
    data() {
        return {
            now: new Date()
        }
    },
//    watch: {
//        rootDate: function (/*val, oldVal*/) {            
//            this.init();
//        }
//    },
    //inject: ["languageService"],
    emits: ["onSelect"],
    mounted() {
//        this.languageService.getText(EnumResourceStrings.Days).then(x => this.labels.strDays = x);
//        this.languageService.getText(EnumResourceStrings.Hours).then(x => this.labels.strHours = x);
//        this.languageService.getText(EnumResourceStrings.Minutes).then(x => this.labels.strMinutes = x);
//        this.languageService.getText(EnumResourceStrings.Weeks).then(x => this.labels.strWeeks = x);
//        this.languageService.getText(EnumResourceStrings.Month).then(x => this.labels.strMonth = x);
//        this.onInit();
//        this.init();
    },
    computed: {
        calendarHeader: function () {

            var result = '';
            var weekDay = '';
            var month = '';
            var untilMonth = '';
            var dateUntil = new Date(this.rootDate);
            dateUntil.setDate(dateUntil.getDate() + 6);
            if (this.weekDays) {
                weekDay = this.weekDays[this.rootDate.getDay()];
            }
            if (this.months) {
                month = this.months[this.rootDate.getMonth()];
                untilMonth = this.months[dateUntil.getMonth()];
            }
            if (weekDay && month) {
                var showYear = this.rootDate.getFullYear() != dateUntil.getFullYear();
                result = month + ' ' + this.rootDate.getDate() + (showYear ? ' ' + this.rootDate.getFullYear() : '');
                result += ' - ' + untilMonth + ' ' + dateUntil.getDate() + (showYear ? ' ' + dateUntil.getFullYear() : '');
            }
            return result;
        },

    },
    methods: {
        select: function (locationCombination, profileEvent) {
            this.$emit('onSelect', locationCombination, profileEvent);
        },
        tag: function (index) {
            var value = new Date(this.rootDate);
            value.setDate(value.getDate() + index);
            var result = this.weekDays[value.getDay()] + ' ';

            return result;
        },
        tag2: function (index) {
            var value = new Date(this.rootDate);
            value.setDate(value.getDate() + index);
            var result = '';
            if (value.getHours && value.getHours() == 0) {
                result += utilService.formatDate(value, 'MM/DD');
            }
            return result;
        },

    },
}