import { IonRow, IonCol } from '@ionic/vue';
import CalendarItem from '../calendar-item';
import {IntervalEnum} from '@/constants/enums';
import { EnumResourceStrings } from '@/constants/enumresourcestrings';

export default {
    components: {
        IonRow,
        IonCol,
        CalendarItem
    },

    props: {
        parent: null,
        startTimeUnit: null,
        timeUnits: null,
        rootDate: Date,
        weekDays: null,
        shortWeekDays: null,
        months: null,
        intervalEnum: IntervalEnum,
        calendarEvents: null
    },
    data() {
        return {
            now: new Date(),
            startWeek: null,
            strWeek: null,
            strShortWeek: null
        }
    },
    watch: {
        rootDate: function (/*val, oldVal*/) {
            this.init();
        }
    },
    inject: ["languageService"],
    emits: ["onSelect"],
    mounted() {
        this.languageService.getText(EnumResourceStrings.Week).then(x => this.strWeek = x);
        this.languageService.getText(EnumResourceStrings.ShortWeek).then(x => this.strShortWeek = x);
//        this.languageService.getText(EnumResourceStrings.Hours).then(x => this.labels.strHours = x);
//        this.languageService.getText(EnumResourceStrings.Minutes).then(x => this.labels.strMinutes = x);
//        this.languageService.getText(EnumResourceStrings.Weeks).then(x => this.labels.strWeeks = x);
//        this.languageService.getText(EnumResourceStrings.Month).then(x => this.labels.strMonth = x);
//        this.onInit();
        this.init();
    },
    computed: {
        startWeekTimeUnit: function () {
            var startDate = new Date(this.rootDate);
            if (this.padStartDays) {
                startDate.setDate(startDate.getDate() - this.padStartDays);
            }
            return {
                isWeek: true,
                rootDate: startDate,
                week: this.startWeek
            };
        },
        timeUnitsWithWeek: function () {
            var result = [];
            var index = 0;
            var startDays = this.padStartDays;
            var startWeek = this.startWeek;
            var daysIntoMonth = 1;
            this.timeUnits.forEach(timeUnit => {
                if ((index++ + startDays + 1) % 7 == 0) {
                    var startDate = new Date(this.rootDate);
                    if (daysIntoMonth) {
                        startDate.setDate(startDate.getDate() + daysIntoMonth);
                    }
                    result.push({
                        isWeek: true,
                        rootDate: startDate,
                        week: ++startWeek});
                }
                result.push(timeUnit);
                daysIntoMonth++;
            });
            //console.log('timeUnitsWithWeek', result);
            return result;
        },
        padStartDays: function () {
            //return this.rootDate.getDay() + 6 % 7;
            return this.rootDate.getDay() % 7;
        },
        padEndDays: function () {
            return 7 - ((this.timeUnits.length + 1 + this.padStartDays) % 7);
        },
        calendarHeader: function () {
            return this.months && this.months[this.rootDate.getMonth()] ? (this.months[this.rootDate.getMonth()] + ' ' + this.rootDate.getFullYear()) : null;
        }
    },
    methods: {
        init: function () {
            var fromDate = new Date(this.rootDate.valueOf());
            this.startWeek = this.getWeek(fromDate);

        },
        getWeek: function (date) {
            var result = 0;
            if (date && date.getFullYear) {
                var onejan = new Date(date.getFullYear(), 0, 1);
                result = Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
            }
            return result;
        },
        weekClass: function (timeUnit) {
            return timeUnit.isWeek ? "week pointer" : "";
        },
        selectWeek: function (startDate) {
            this.parent.selectWeek(startDate);
            //console.log('selectWeek', startDate);
        },
        select: function (locationCombination, profileEvent) {
            this.$emit('onSelect', locationCombination, profileEvent);
        }
    },
}