import { IonButtons, IonButton, IonGrid, IonRow, IonItem, IonCol, IonIcon } from '@ionic/vue';
import CalendarItem from '../calendar-item';
import CalendarMonth from '../calendar-month';
import CalendarYear from '../calendar-year';
import CalendarDay from '../calendar-day';
import CalendarWeek from '../calendar-week';
import CalendarMultiYear from '../calendar-multiyear';
import router from '@/router';
import utilService from '@/services/utilService';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import {StorageEnum, IntervalEnum} from '@/constants/enums';
import {TimeUnit, DateStruct} from '@/models/common';
import {AspectTemplate} from '@/models/luck';

import { addIcons } from 'ionicons';
//import { trash, addCircle as add, caretBackOutline as arrowleft   } from 'ionicons/icons';
import { stopwatch as go, addCircle as arrowright,
        removeCircle as arrowleft, caretUpOutline as arrowup     } from 'ionicons/icons';



export default {
    components: {
        IonButtons,
        IonButton,
        IonGrid,
        IonRow,
        IonCol,
        IonIcon,
        IonItem,
        CalendarItem,
        CalendarMonth,
        CalendarDay,
        CalendarWeek,
        CalendarYear,
        CalendarMultiYear
    },

    props: {
        loadTrigger: null
    },
    inject: ["storageService", "languageService", "locationCombinationService", "calendarService", "aspectTemplateService", "licenseService"],

    setup() {
        addIcons({
            arrowleft,
            arrowright,
            arrowup,
            go
        });
    },
    data() {//needed for 2-way and late binding
        return{
            IntervalEnum: IntervalEnum,
            labels: {
                strDays: "Days",
                strWeeks: "Weeks",
                strHours: "Hours",
                strMinutes: "Minutes",
                strMonth: ""
            },

            timeUnits: [],
            stepInterval: new TimeUnit(),
            intervalEnum: IntervalEnum,
            rootDateStruct: new DateStruct(),
            showHistoryButton: false,
            optionItemWidth: null,
            rootDate: null,
            calendarEvents: {},
            weekDays: [],
            shortWeekDays: [],
            months: [],
            aspectTemplate: AspectTemplate,
            outerLimit: null
        }
    },
    watch: {
        loadTrigger: function () {
            this.init();
        }
    },
    computed: {
        rootDateComputed: function () {
            var result = this.rootDate;
            switch (this.intervalEnum) {
                case IntervalEnum.Month:
                    result.setDate(1);
                    break;
                case IntervalEnum.Day:
                    result.setHours(0);
                    break;
                case IntervalEnum.Year:
                    result.setMonth(0);
                    result.setDate(1);
                    break;
                case IntervalEnum.MultiYear:
                    var year = result.getFullYear();
                    year -= (year % 12);
                    result.setFullYear(year);
                    result.setMonth(0);
                    result.setDate(1);
                    break;
            }
            return result;
        },
        calendarEventsComputed: function () {
            return this.calendarEvents;
        },
        stepIntervalSet: function () {
            return this.stepInterval && (this.stepInterval.day || this.stepInterval.hour || this.stepInterval.minute);
        },
        showUp: function () {
            return this.intervalEnum == IntervalEnum.Day
                    || this.intervalEnum == IntervalEnum.Month
                    || this.intervalEnum == IntervalEnum.Year
                    || this.intervalEnum == IntervalEnum.Week;
        }
    },

    mounted() {
        this.languageService.getText(EnumResourceStrings.Days).then(x => this.labels.strDays = x);
        this.languageService.getText(EnumResourceStrings.Hours).then(x => this.labels.strHours = x);
        this.languageService.getText(EnumResourceStrings.Minutes).then(x => this.labels.strMinutes = x);
        this.languageService.getText(EnumResourceStrings.Weeks).then(x => this.labels.strWeeks = x);
        this.languageService.getText(EnumResourceStrings.Month).then(x => this.labels.strMonth = x);
        //this.init();
    },

    methods: {
        init: function () {

            var promises = [];

            promises.push(this.storageService.get(StorageEnum.CalendarIntervalEnum).then(x => {
                this.intervalEnum = x;
                if (!this.intervalEnum) {
                    this.intervalEnum = IntervalEnum.Month;
                    promises.push(this.storageService.set(StorageEnum.CalendarIntervalEnum, this.intervalEnum));
                }
            }));
            promises.push(this.calendarService.getEvents().then(x => {
                this.calendarEvents = x;
            }));
            promises.push(this.languageService.getMonths().then(months => {
                this.months = months;
            }));
            promises.push(this.languageService.getWeekDays().then(weekDays => {
                this.weekDays = weekDays;
            }));
            promises.push(this.languageService.getWeekDays(true).then(weekDays => {
                this.shortWeekDays = weekDays;
            }));
            promises.push(this.aspectTemplateService.getCurrentAspectTemplate().then(aspectTemplate => {
                //console.log('aspectTemplate', aspectTemplate);
                this.aspectTemplate = aspectTemplate;
            }));

            promises.push(this.licenseService.getLicense().then(x => {
                this.outerLimit = x.license.propertiesVm.aspectSecondaryDegreeAsDouble;
            }));

            Promise.all(promises).then(() => {
                //console.log('calendarEvents', calendarEvents);
                this.setCalendarToNow();

            });
        },

        setCalendarToNow: function () {
            var now = new Date();
            //now.setHours(0);
            //now.setMinutes(0);
            this.rootDate = now;
            now.setMinutes(0);
            now.setMilliseconds(0);
            now.setSeconds(0);
            this.rootDateStruct.loadFromDate(now, false);
            this.setTimeUnits(this.rootDateStruct).then(() => {
                return this.loadCalendar();
            });
        },
        setTimeUnits: function (dateStruct) {
            var month = Number(dateStruct.month);
            var year = Number(dateStruct.year);
            var daysInMonth = this.calendarService.getDaysInMonth(month, year);
            switch (this.intervalEnum) {
                case IntervalEnum.Year:
                    this.timeUnits = this.calendarService.generateTimeUnitsForYear(year);
                    break;
                case IntervalEnum.MultiYear:
                    this.timeUnits = this.calendarService.generateTimeUnitsForMultiYear(year);
                    break;
                default:
                    this.timeUnits = this.calendarService.generateTimeUnitsForInterval(this.intervalEnum, daysInMonth);
                    break;
            }
            this.stepInterval = this.timeUnits[this.timeUnits.length - 1];
            return this.storageService.set(StorageEnum.CalendarTimeUnits, this.timeUnits);
        },
        formatDate: function (value) {
            return utilService.formatDate(value);
        },
        select: function (locationCombination, profileEvent) {
            var promises = [];
            //console.log('select', locationCombination, profileEvent);
            promises.push(this.locationCombinationService.setSelectedLocationCombination(locationCombination));
            promises.push(this.locationCombinationService.setSelectedEvent(profileEvent));
            promises.push(this.storageService.setDate(StorageEnum.Event, profileEvent.date));
            Promise.all(promises).then(() => {
                router.replace('/tabs/dashboard');
            });
        },

        clear: function () {
            //this.luckFigures = [];
        },
        setOptionsWidth: function () {
            var width = this.$el.offsetWidth;
            //IonItemOption expandable doesn't work (yet), so pass required width along
            if (width) {
                if (width > 500) {
                    width /= 2;
                }
                width -= 50;
            }
            this.optionItemWidth = width;
            //console.log('luckfigurepanel width', this.$el.offsetWidth);
        },

        loadCalendar: function () {
            //this.currentDrillDownDepth = 0;
            this.childLuckFigure = null;
            this.childFutureLuckFigures = null;

            this.setOptionsWidth();
            var promises = [];
            this.storageService.get(StorageEnum.ShowHistoryButton).then(x => {
                this.showHistoryButton = x;
            });
            var timeUnits = null;
            promises.push(this.storageService.get(StorageEnum.CalendarTimeUnits).then(x => {
                if (x) {
                    timeUnits = x;
                }
            }));

            return Promise.all(promises).then(() => {
                this.timeUnits = timeUnits;

                if (this.timeUnits && this.timeUnits.length > 0) {
                    this.stepInterval = this.timeUnits[this.timeUnits.length - 1];
//                    console.log('step', this.stepInterval);
                }
            });
        },

        moveCalendar: function (dateStruct) {
            this.setTimeUnits(dateStruct).then(() => {
                this.loadCalendar();
            });
        },
        selectWeek: function (date) { //calendar-month can drilldown to day as well as week
            console.log('selectWeek', date);
            var dateStruct = new DateStruct();
            date.setHours(0);
            dateStruct.loadFromDate(date);
            this.rootDate = date;
            this.intervalEnum = IntervalEnum.Week;
            this.moveCalendar(dateStruct);
        },
        drillDown: function (date) {
            console.log('drillDown', date);
            var dateStruct = new DateStruct();
            dateStruct.loadFromDate(date);
            this.rootDate = date;
            switch (this.intervalEnum) {
                case IntervalEnum.Month:
                    this.intervalEnum = IntervalEnum.Day;
                    break;
                case IntervalEnum.Year:
                    this.intervalEnum = IntervalEnum.Month;
                    break;
                case IntervalEnum.MultiYear:
                    this.intervalEnum = IntervalEnum.Year;
                    break;
            }
            this.moveCalendar(dateStruct);//                                      
        },
        goNext: function () {
            this.move(true, false);
        },
        goPrevious: function () {
            this.move(false, false);
        },
        goUp: function () {
            switch (this.intervalEnum) {
                case IntervalEnum.Day:
                case IntervalEnum.Week:
                    this.intervalEnum = IntervalEnum.Month;
                    break;
                case IntervalEnum.Month:
                    this.intervalEnum = IntervalEnum.Year;
                    break;
                case IntervalEnum.Year:
                    this.intervalEnum = IntervalEnum.MultiYear;
                    break;
            }
            var dateStruct = new DateStruct();
            dateStruct.loadFromDate(this.rootDate, true);
            this.moveCalendar(dateStruct);
        },
        move: function (next) {
            var dateStruct = null;

            dateStruct = this.calendarService.getNextStepDate(this.stepInterval, this.rootDate, next, this.intervalEnum);
            this.rootDate = new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute);
            //console.log('move', this.rootDate.getMonth());

            this.moveCalendar(dateStruct);
        },
        setInterval: function (intervalEnum) {
            //var date = this.luckFigure.rootDate;
            this.storageService.set(StorageEnum.CalendarIntervalEnum, intervalEnum).then(() => {
                this.intervalEnum = intervalEnum;

                this.setTimeUnits(this.rootDateStruct).then(() => {
                    this.moveCalendar(this.rootDateStruct, this.timeUnits);//              
                });
            });
        },
        getIntervalButtonClass: function (intervalEnum) {
            var result = 'button btn width50pct';
            if (intervalEnum == this.intervalEnum)
            {
                result += ' selected';
            }
            return result;
        }

    },
}