import { IonGrid, IonRow, IonCol, IonItem, IonIcon } from '@ionic/vue';
import {IntervalEnum} from '@/constants/enums';
import { star } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import CalendarEvent from '../calendar-event';

export default {
    components: {IonGrid, IonRow, IonCol, IonItem, IonIcon, CalendarEvent},
    setup() {
        addIcons({
            star
        });

    },
    props: {
        parent: null,
        startTimeUnit: null,
        rootDate: Date,
        intervalEnum: IntervalEnum,
        calendarEvents: null,
        showDetails: Boolean,
        now: Date,
        months: null,
        aspectTemplate: null,
        outerLimit: null
    },
    data() {
        return {
            fromDate: Date,
            untilDate: Date,
            localGmtOffsetSeconds: null,
            initCalled: null,
            events: []
        }
    },
    watch: {
        startTimeUnit: function (/*val, oldVal*/) {
            this.init();
        },
        intervalEnum: function (/*val, oldVal*/) {
            this.init();
        },
        rootDate: function (/*val, oldVal*/) {
            this.init();
        },
        calendarEvents: function (/*val, oldVal*/) {
            this.init();
        },
    },
    inject: ["languageService", "calendarService", "storageService", "luckService", "utilService"],
    emits: ["onSelect"],
    mounted() {
        this.init();
    },

//    updated: function () {
//
//    },
    computed: {
        showRemark: function () {
            return this.intervalEnum == IntervalEnum.Day;
        },
        showStarOnly: function () {
            return !this.showDetails && this.events && this.events.length > 0;
        },
        isCurrentTime: function () {
            return this.now && this.fromDate && this.untilDate && this.now >= this.fromDate && this.now < this.untilDate;
        },
        itemClass: function () {
            return this.isCurrentTime ? 'currenttimeblock' : '';
        },
        enrichedEvents: function () {
            return this.events.map(row => {
                return {
                    event: row,
                    eventDate: this.formatEventDate(row.profileEvent)
                };
            });
        }
    },
//    emits: ["onChange", "onFoldersChanged"],
    methods: {
        onClick: function () {
            console.log('onClick', this.fromDate);
            this.parent.drillDown(this.fromDate, false);
        },
        init: function () {
            //this.event = this.profileEvent ? this.profileEvent : new ProfileEvent();            
            //this.event.date = this.luckFigure.eventDateStruct;            

            this.fromDate = new Date(this.rootDate.valueOf());
            this.localGmtOffsetSeconds = this.fromDate.getTimezoneOffset() * -1 * 60;
            if (this.startTimeUnit) {
                if (this.startTimeUnit.day) {
                    this.fromDate.setDate(this.fromDate.getDate() + this.startTimeUnit.day);
                }
                if (this.startTimeUnit.hour) {
                    this.fromDate.setHours(this.fromDate.getHours() + this.startTimeUnit.hour);
                }
                if (this.startTimeUnit.minute) {
                    this.fromDate.setMinutes(this.fromDate.getMinutes() + this.startTimeUnit.minute);
                }
            }

            this.untilDate = new Date(this.fromDate);
            switch (this.intervalEnum) {
                case IntervalEnum.Day:
                    this.untilDate.setHours(this.untilDate.getHours() + 2);
                    break;
                case IntervalEnum.Month:
                    this.untilDate.setDate(this.untilDate.getDate() + 1);
                    this.untilDate.setHours(0);
                    this.fromDate.setHours(0);
                    break;
                case IntervalEnum.Hour:
                    this.untilDate.setHours(this.untilDate.getHours() + 1);
                    break;
                case IntervalEnum.TwoHours:
                    this.untilDate.setHours(this.untilDate.getHours() + 2);
                    break;
                case IntervalEnum.Week:
                    this.untilDate.setHours(this.untilDate.getHours() + 6);
                    break;
                case IntervalEnum.Year:
                    var daysInMonth = this.calendarService.getDaysInMonth(this.untilDate.getMonth(), this.untilDate.getFullYear());
                    this.untilDate.setDate(this.untilDate.getDate() + daysInMonth);
                    this.untilDate.setHours(0);
                    this.fromDate.setHours(0);
                    break;
                case IntervalEnum.MultiYear:
                    this.untilDate.setDate(this.untilDate.getDate() + (this.fromDate.getFullYear() % 12 == 0 ? 365 : 366));
                    this.untilDate.setHours(0);
                    this.fromDate.setHours(0);
                    break;
            }

            this.loadEvents();
        },
        loadEvents: function () {
            if (this.calendarEvents) {
                //        console.log('loadEvents',this.fromDate, this.untilDate);
                var minimal = false;
                switch (this.intervalEnum) {
                    case IntervalEnum.Year:
                    case IntervalEnum.Month:
                    case IntervalEnum.MultiYear:
                        minimal = true;
                        break;
                }
                this.events = this.calendarService.getEventsForDateRange(this.calendarEvents, this.fromDate, this.untilDate, minimal);
                //console.log('events', this.events.length, this.events, Object.keys(this.calendarEvents));
            }


        },
        selectEvent: function (item) {
            this.$emit('onSelect', item.locationCombination, item.profileEvent);
        },
        formatDate: function (value) {
            var format = 'MM/DD/YYYY HH:mm:SSS';
            var result = '';
            switch (this.intervalEnum) {
                case IntervalEnum.Month:
                    format = 'D';
                    result = this.utilService.formatDate(value, format);
                    break;
                case IntervalEnum.Day:
                    format = 'HH:mm';
                    result = this.utilService.formatDate(value, format);
                    break;
                case IntervalEnum.Week:
                    format = 'HH:mm';
                    result += this.utilService.formatDate(value, format);
                    break;
                case IntervalEnum.Year:
                    if (this.months && Object.keys(this.months).length > 0 && value.getMonth) {//sic getMonth function is sometimes not defined onload
                        result = this.months[value.getMonth()];
                    }
                    break;
                case IntervalEnum.MultiYear:
                    result = value.getFullYear ? value.getFullYear() : '';
                    break;
            }

            return result;
        },
        formatEventDate: function (profileEvent) {
            var date = profileEvent.date;
            var value = new Date(date.year, date.month, date.day, date.hour, date.minute, date.second, date.milliSecond);
            if (this.localGmtOffsetSeconds != profileEvent.gmtOffset) {
                console.log('gmt check', value);
                value.setSeconds(value.getSeconds() + (this.localGmtOffsetSeconds - profileEvent.gmtOffset));
            }
            var format = 'HH:mm';
            return  this.utilService.formatDate(value, format);
        },
    }

}