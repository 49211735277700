<ion-grid class="nomargin">        
    <ion-row class="nomargin">
        <ion-col class="nomargin">
            <ion-buttons class="nomargin buttons" color='tertiary'>      
                <ion-button class="button btn width60" v-if="stepIntervalSet && showUp"  @click="goUp" >
                    <ion-icon  name="arrowup" size="large" class="minwidth50  panelicon" ></ion-icon>
                </ion-button>                                    
                <ion-button class="button btn width60" v-if="stepIntervalSet"  @click="goPrevious">
                    <ion-icon  name="arrowleft" size="large" class="minwidth50 panelicon"></ion-icon>
                </ion-button>                    
                <ion-button class="button btn btn-large center fullwidth"  @click="setCalendarToNow">
                    <ion-icon  name="go" size="large" class="panelicon"></ion-icon>                
                </ion-button>                                    
                <ion-button class="button btn width60" v-if="stepIntervalSet"  @click="goNext">
                    <ion-icon  name="arrowright" size="large" class="minwidth50  panelicon" ></ion-icon>
                </ion-button>                                    
            </ion-buttons>
        </ion-col>
    </ion-row>        
    <ion-row v-if="rootDate">
        <ion-col size="12" v-if="intervalEnum == IntervalEnum.Month">
            <calendar-month :parent="this"
                            :rootDate="rootDateComputed"
                            :timeUnits="timeUnits"
                            :startTimeUnit="null" 
                            :weekDays="weekDays"
                            :shortWeekDays="shortWeekDays"
                            :months="months"
                            :calendarEvents="calendarEventsComputed"
                            :intervalEnum="intervalEnum"                            
                            @onSelect="select"
                            ></calendar-month>                
        </ion-col>
        <ion-col size="12" v-if="intervalEnum == IntervalEnum.Day">
            <calendar-day :parent="this"
                            :rootDate="rootDateComputed"
                            :timeUnits="timeUnits"
                            :startTimeUnit="null"
                            :weekDays="weekDays"                            
                            :months="months"
                            :aspectTemplate="aspectTemplate"                            
                            :outerLimit="outerLimit"
                            :calendarEvents="calendarEventsComputed"
                            :intervalEnum="intervalEnum"                            
                            @onSelect="select"
                            ></calendar-day>                
        </ion-col>
        <ion-col size="12" v-if="intervalEnum == IntervalEnum.Week">
            <calendar-week :parent="this"
                            :rootDate="rootDateComputed"
                            :timeUnits="timeUnits"
                            :startTimeUnit="null"
                            :weekDays="weekDays"                            
                            :shortWeekDays="shortWeekDays"
                            :months="months"
                            :aspectTemplate="aspectTemplate"                            
                            :outerLimit="outerLimit"
                            :calendarEvents="calendarEventsComputed"
                            :intervalEnum="intervalEnum"                            
                            @onSelect="select"
                            ></calendar-week>                
        </ion-col>
        <ion-col size="12" v-if="intervalEnum == IntervalEnum.Year">
            <calendar-year :parent="this"
                            :rootDate="rootDateComputed"
                            :timeUnits="timeUnits"
                            :startTimeUnit="null" 
                            :months="months"
                            :calendarEvents="calendarEventsComputed"
                            :intervalEnum="intervalEnum"                            
                            @onSelect="select"
                            ></calendar-year>                
        </ion-col>
        <ion-col size="12" v-if="intervalEnum == IntervalEnum.MultiYear">
            <calendar-multi-year :parent="this"
                            :rootDate="rootDateComputed"
                            :timeUnits="timeUnits"
                            :startTimeUnit="null"                             
                            :calendarEvents="calendarEventsComputed"
                            :intervalEnum="intervalEnum"                            
                            @onSelect="select"
                            ></calendar-multi-year>                
        </ion-col>
    </ion-row>
</ion-grid>            
