<ion-row>
    <ion-col size="12">
        <div v-if="rootDate && calendarHeader" v-html="calendarHeader" class="calendarHeader"></div>
    </ion-col>
                
    <ion-col size="4">        
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="null" 
                       :now="now"
                       :showDetails="false"
                       :calendarEvents="calendarEvents"
                       :months="months"
                       :intervalEnum="intervalEnum"                       
                       @onSelect="select"
                       ></calendar-item>                
    </ion-col>
    <ion-col size="4" v-for="timeUnit in timeUnits" >            
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="timeUnit" 
                       :now="now"
                       :showDetails="false"
                       :calendarEvents="calendarEvents"
                       :months="months"
                       :intervalEnum="intervalEnum"                                                  
                       @onSelect="select"
                       ></calendar-item>

    </ion-col>    
</ion-row>