<ion-row>
    <ion-col size="3">        
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="null" 
                       :now="now"
                       :showDetails="false"
                       :calendarEvents="calendarEvents"
                       :intervalEnum="intervalEnum"                       
                       @onSelect="select"
                       ></calendar-item>                
    </ion-col>
    <ion-col size="3" v-for="timeUnit in timeUnits">            
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="timeUnit" 
                       :now="now"
                       :showDetails="false"
                       :calendarEvents="calendarEvents"
                       :intervalEnum="intervalEnum"                                                  
                       @onSelect="select"
                       ></calendar-item>

    </ion-col>    
</ion-row>