<ion-row>
    <ion-col>
        <div v-if="calendarHeader" v-html="calendarHeader" class="calendarHeader"></div>
    </ion-col>
</ion-row>    
<ion-row>
    <ion-col size-md="3" size-xs="12">        
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="null" 
                       :now="now"
                       :showDetails="true"
                       :calendarEvents="calendarEvents"
                       :aspectTemplate="aspectTemplate"                       
                       :outerLimit="outerLimit"
                       :intervalEnum="intervalEnum"
                       @onSelect="select"
                       ></calendar-item>                
    </ion-col>
    <ion-col size-md="3"  size-xs="12" v-for="timeUnit in timeUnits" >            
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="timeUnit" 
                       :now="now"
                       :showDetails="true"
                       :calendarEvents="calendarEvents"
                       :aspectTemplate="aspectTemplate"                       
                       :outerLimit="outerLimit"
                       :intervalEnum="intervalEnum"                           
                       @onSelect="select"
                       ></calendar-item>

    </ion-col>    
</ion-row>