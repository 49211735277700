<ion-row class="seven-cols">    
    <ion-col size="12">
        <div v-if="rootDate && calendarHeader" v-html="calendarHeader" class="calendarHeader"></div>
    </ion-col>
    <ion-col class="week weekDaysHeader" v-html="strWeek"></ion-col>
    <ion-col class="week shortWeekDaysHeader" v-html="strShortWeek"></ion-col>
    <ion-col v-for="weekDay in weekDays" class="weekDaysHeader">
        <div v-html="weekDay"></div>
    </ion-col>
    
    <ion-col v-for="weekDay in shortWeekDays" class="shortWeekDaysHeader">
        <div v-html="weekDay"></div>
    </ion-col>
    
    <ion-col class="week pointer">
        <div v-html="startWeekTimeUnit.week" @click="selectWeek(startWeekTimeUnit.rootDate)"></div>
    </ion-col>
    
    <ion-col v-for="weekDay in padStartDays" class="weekDaysPadding">
        
    </ion-col>    
    
    <ion-col>        
        <calendar-item :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="null" 
                       :now="now"
                       :showDetails="false"
                       :calendarEvents="calendarEvents"
                       :intervalEnum="intervalEnum"
                       @onSelect="select"                       
                       ></calendar-item>                
    </ion-col>
    <ion-col v-for="timeUnit in timeUnitsWithWeek" :class="weekClass(timeUnit)">            
        <div v-if="timeUnit.isWeek" v-html="timeUnit.week" @click="selectWeek(timeUnit.rootDate)"></div>
        <calendar-item v-if="!timeUnit.isWeek" :parent="parent"
                       :rootDate="rootDate"
                       :startTimeUnit="timeUnit" 
                       :now="now"
                       :showDetails="false"
                       :calendarEvents="calendarEvents"
                       :intervalEnum="intervalEnum"                                                  
                       @onSelect="select"
                       ></calendar-item>

    </ion-col>
    <ion-col v-for="weekDay in padEndDays" class="weekDaysPadding">
        
    </ion-col>
</ion-row>