import { IonPage, IonContent } from '@ionic/vue';
import PageHeader from '@/components/page-header';
import CalendarPanel from '@/calendar-page/calendar-panel';
import { thumbsUp as thumbsup, thumbsDown as thumbsdown } from 'ionicons/icons';
import { addIcons } from 'ionicons';



export default {

    components: {
        IonPage, IonContent, PageHeader, CalendarPanel
    },
    setup() {
        addIcons({
            thumbsup,
            thumbsdown
        });

    },
    data() {
        return{
            loadTrigger: 0
        }
    },
    
    computed: {
        calendarPanel: function () {
            return this.$refs.calendarPanel;
        },
    },
    mounted() {
        this.init();
    },

    ionViewDidEnter() {
        if (this.firstMount) {
            this.firstMount = false;
        } else {
            this.init();
        }
    },
    ionViewWillLeave: function () {
        if (!this.redirecting) {
            return this.save();
        }
    },
    methods: {
        init: function () {
            this.loadTrigger++;
            this.ensureOptionItemWidth();
        },
        setOptionsWidth: function () {
            if (!this.optionItemWidth) {
                var width = this.$el.offsetWidth;
                if (width > 500) {
                    width /= 2;
                }
                if (width) {
                    this.optionItemWidth = width - 50;
                }
            }
        },
        ensureOptionItemWidth: function () {
            var counter = 1;
            var interval = setInterval(() => {
                console.log('going for width', interval);
                this.setOptionsWidth();
                if (this.optionItemWidth > 0 || counter++ > 50) {
                    clearInterval(interval);
                }
            }, 100);
        },
        save: function () {

        }
    }
}
