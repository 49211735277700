<ion-item lines="none" v-if="fromDate" @click="onClick" :class="itemClass">
    <ion-grid>
        <ion-row>           
            <ion-col>                                
                <span v-html="formatDate(fromDate)"></span>                
                <ion-icon v-if="showStarOnly" name="star" class="float-right star"></ion-icon>                
            </ion-col>                        
        </ion-row>        
        <ion-row v-if="!showStarOnly" v-for="item in enrichedEvents" @click="selectEvent(item.event)">                    
            <ion-col size="2" v-html="item.eventDate"></ion-col>
            <ion-col size="4" v-html="item.event.locationCombination.name"></ion-col>            
            <ion-col size="6">
                <calendar-event 
                    :event="item.event"
                    :locationCombination="item.event.locationCombination"
                    :aspectTemplate="aspectTemplate"
                    :outerLimit="outerLimit"
                    ></calendar-event>
            </ion-col>            
            <ion-col size="12" v-if="item.event.profileEvent.remark && showRemark">
                <i v-html="item.event.profileEvent.remark"></i>
            </ion-col>                        
        </ion-row>
    </ion-grid>
</ion-item>